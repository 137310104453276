.center
{
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 100px;
  border-radius: 3px;
}

.footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  font-family: "Roboto", sans-serif;
  text-align: center;
  background: #F2F2F2;
}

.header-text {    
  font-weight: 500;
  font-size: 1.2em;
}

.wrap {
  padding-left: 5%;
  padding-right: 5%;
}

.bigwrap {
  padding-left: 8%;
  padding-right: 8%;
}

.smallwrap {
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 10px;
}

.textTheme {
  color: #58402C;
}

.body {
  background: rgba(252, 252, 252, 0.32);
  padding-bottom: 50px;
}

.ui.modal {
  top: 1%;
}